.filterSortContainer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-bottom: 0.75rem;
}
.filterCheckboxes {
  display: flex;
  flex-direction: column;
}

.sortFilterText {
  cursor: pointer;
}

@media (min-width: 768px) {
  .filterSortContainer {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px;
    gap: 1rem;
    position: sticky;
    top: 0;
  }
  .sortSelect {
    width: 100%;
  }
}

.tag {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.25rem;
  padding: 0 1rem 0.75rem 1rem;

  .selectedSortTag {
    width: max-content;
    border-radius: 14px;
    margin: 0;
  }
}

.sortOptions {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.button {
  border-bottom: none;
}
.city-dropdown {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.city-select {
  margin-top: 10px;
  width: 100%;
  max-width: 400px;
}

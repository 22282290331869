@import "../../variables";
.duration-counter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .ant-btn {
    border-radius: 10px;
    margin: 10px;
    color: black;
  }
}

.ant-input-number {
  width: 70%;
  padding: 5px;
  font-size: 14px;
  border-radius: 0;
  border: 1px solid $border-color;
}

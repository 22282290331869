.about-us-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .heading {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    text-align: center;
  }
  .section {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}

.turf-data {
  padding: 16px;
}

.packages-container {
  height: 130px;
  overflow-y: auto;
  margin-bottom: 16px;
}

.hovered-package {
  background-color: #f0f0f0;
}

.area-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .single-pitch-details {
//   text-align: center;
// }

.back-to-overview-link {
  margin: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .packages-container {
    height: auto;
    max-height: 300px;
  }

  .area-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .area-container > * {
    margin-bottom: 8px;
  }
  
  .back-to-overview-link {
    font-size: 1.1em;
  }
}

@import "../../variables";

.booking-confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;

  .success-card {
    max-width: 50rem;
    padding: 2rem;
    text-align: center;
    border: 1px solid $border-color;
    box-shadow: 0 0 2rem $border-color;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    .header h2 {
      margin-bottom: 0.5rem;
    }
  }

  .ant-btn {
    width: fit-content;
    font-weight: 600;
    font-size: 16px;
    height: fit-content;
    padding: 0.5rem 3rem;
  }

  .highlighted-text {
    color: #b37feb;
  }

  .booking-timeline {
    margin-top: 2rem;
  }
  
  .booking-timeline .ant-timeline-item {
    padding-bottom: 0.3rem;
  }

  .booking-timeline .ant-timeline-item-content {
    text-align: left !important;
  }

  .booking-timeline .ant-timeline-item-content {
    font-size: 2rem;
  }

}

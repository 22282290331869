@import "../../../variables";
.main-container {
  display: flex;
  min-height: 70vh;
  align-items: center;
  padding: 20px;
}
.booking-container {
  width: auto;
  margin: auto;
  display: flex;
  border: 1px solid $border-color;

  span.title {
    background-color: $light-bg;
    padding: 1rem;
    * {
      color: white;
    }
    min-width: 5rem;
    width: 15rem;
  }

  .booking-form {
    padding: 1rem;
    min-width: 10rem;
    max-width: 30rem;
  }

  .react-datepicker-wrapper {
    input {
      width: 100%;
      padding: 10px;
      font-size: 14px;
      border-radius: 0;
      border: 1px solid $border-color;
    }
    // width: 100%;
  }

  .react-datepicker-popper {
  left: 2rem !important;
    // width: 30rem;
  }

  @media screen and (max-width: 767px) {
    & {
      flex-direction: column;

      span.title {
        min-width: unset;
        width: unset;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .main-container {
    min-height: 90vh;
  }
}

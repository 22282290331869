.turf-data {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  .turf-details {
    display: flex;
    flex: 1;
    align-items: end;
    gap: 0.5rem;
  }

  .area-container {
    display: flex;

    @media screen and (max-width: 767px) {
      & {
        flex-direction: column;
        align-items: start;
        gap: 0;
      }
    }
    gap: 0.25rem;
    align-items: center;
    z-index: 10;
    .turf-area-options {
      display: flex;
      gap: 0.5rem;
    }
  }
}

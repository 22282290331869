@import "../../../variables";

.feedback-container {
  h1 {
    text-align: center;
  }
  margin: 2.5rem auto;
  max-width: 40rem;
  border-radius: 16px;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  padding: 4rem 2rem;


  .emoji {
    font-size: 32px; /* Default size */
    opacity: 1;
    transition: font-size 0.3s; /* Smooth transition effect */
  }
  
  /* Increase size on hover */
  .emoji:hover {
    font-size: 40px; /* Increased size on hover */
  }
}

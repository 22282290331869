@import "../../variables";

.header-container {
  position: sticky;
  top: 0;
  z-index: 100;
  align-items: center;
  display: flex;
  justify-content: space-between;
  backdrop-filter: blur(1rem);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .header-content {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    gap: 0.75rem;

    .logo-image {
      height: 42px;
      height: 30px;
    }

    .log-container {
      height: 50px;
      font-size: 10px;
    }

    @media only screen and (max-width: 600px) {
      .logo-image {
        width: 200px;
        height: 20px;
      }
    }

    span.ant-typography {
      color: white;
      font-weight: 600;
      font-size: 1.5rem;
    }
  }
  .location-icon {
    background: white;
    display: flex;
    padding: 11px;
    border-radius: 20px;
    &:hover{
      cursor: pointer;
    }
  }
  .search-location {
    display: flex;
    width: 100%;
    justify-content: end;
  }

  @media only screen and (max-width: 600px) {
    .search-location {
      width: auto;
    }
    .location-icon {
      padding: 7px;
    }
   
  }
}

.disclaimer-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .heading {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    text-align: center;
  }
  .section {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    h4 {
      margin-bottom: 0.85rem;
    }
  }
}

// @import "../../variables";

// .modal {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background: #fff;
//   padding: $p-4;
//   border-radius: $border-medium;
//   box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
//   max-width: 90vw;
//   width: 50%;
//   color: $primary-color;
//   overflow: hidden;
//   z-index: 1000;
//   text-align: center;
//   border: 2px solid $quinary-color;

//   .modal-content {
//     max-height: 60vh;
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     overflow-y: auto;    
//   }

//   .content {
//     flex: 1;
//     overflow-y: auto;
//   }

//   h1 {
//     font-size: 28px;
//     text-align: center;
//     margin-bottom: $m-3;
//   }

//   section {
//     margin-bottom: $m-1;
//     // padding-left: 100px;
//     // padding-right: 100px;

//     h2 {
//       text-align: left;
//       // color: $quinary-color;
//       padding-left: 75px;
//       font-weight: $normal-text-font-weight;
//       font-size: 20px;
//     }

//     ul {
//       // padding-left: 200px;
//       // padding-right: 100px;
//       padding-top: $p-2;
//       padding-bottom: $p-2;
//       margin: 0;

//       li {
//         margin-bottom: $m-2;
//         font-size: $medium-font;
//         line-height: $line-height-normal;
//         text-align: left;
//         padding-left: 100px;
//         padding-right: 100px;
//         list-style-type: circle;

//         &:before {
//           content: '\2022';
//           font-size: 22px;
//           left: 20px;
//           margin-left: -10px;
//         }

//         strong {
//           font-size: $medium-font;
//           margin-right: $m-2;
//         }
//       }
//     }
//   }


//         content: '\2022';
//   button {
//     background-color: $tertiary-color;
//     color: $secondary-color;
//     font-size: 18px;
//     padding: $p-2 $p-4;
//     border: none;
//     border-radius: $border-small;
//     cursor: pointer;
//     transition: background-color 0.3s ease;
//     display: block;
//     margin: $m-3 auto;

//     &:hover {
//       background-color: darken($tertiary-color, 10%);
//     }

//     &:disabled {
//       background-color: $quaternary-color;
//       cursor: not-allowed;
//     }
//   }
// }

// .bottom-section {
//   position: sticky;
//   bottom: 0;
//   padding: 10px;
//   background: #fff;
//   border-top: 1px solid #ccc;
//   width: 100%;
//   text-align: center;
//   z-index: 1001;
// }

// .overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5);
//   // z-index: 1;
//   // display: none;
// }

// .overlay.active {
//   display: block;
// }

// @media (max-width: 768px) {
//   .modal {
//     width: 90%;
//     padding: $p-2;
//   }

//   section {
//     margin-bottom: $m-1;

//     h2 {
//       padding-left: 20px;
//     }

//     ul {
//       padding-left: 20px;
//       padding-right: 10px;
//     }
//   }
// }
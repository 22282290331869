@import "../../variables";
.pac-container {
  width: 300px !important;
}

.location-container {
  -webkit-box-flex: 1;
  display: flex;
  flex-grow: 1;
  height: 3.6rem;
  margin-bottom: 10px;
  box-shadow: rgba(28, 28, 28, 0.06) 0px 1px 4px;
  padding: 1.1rem 1.4rem 1.1rem 2.5rem;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  align-items: center;
  .location-text {
    font-size: 1rem;
    letter-spacing: -0.03rem;
    font-weight: 500;
    max-width: 60vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 0.4rem;
    text-transform: uppercase;
    
  }
}

.button {
  margin-top: 10px;
  height: 55px;
  border: none;
  border-bottom: 1px solid #d9d9d0;
  width: 100%;
  p {
    margin: 0px;
    text-align: left;
    margin: 0px 0px 0px 1.6rem;
  }
}
.current-location {
  display: flex;
  line-height: 1.5;
  align-items: center;
  color: $tertiary-color;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  span {
    margin-left: 8px;
  }
}

.carousal-image {
  position: relative;
  height: 250px; /* Set this to the desired height */
  overflow: hidden;
}

.lazy-load-image-background {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .image {
    height: 18em;
  }
}

.carousal-title {
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  backdrop-filter: blur(1rem);
  z-index: 10;
  * {
    color: white !important;
  }
}

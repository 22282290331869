@import "../../../../variables";
.card-container {
  cursor: pointer;
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  border: 1px solid $border-color;
  transition: all 150ms ease-out;
  overflow: hidden;

  .hover {
    width: 3rem;
    height: 3rem;
    background-color: $hover-cursor-bg;
    filter: blur(3rem);
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &:hover {
    border: 1px solid $border-color-hover;
    box-shadow: 0 0 1rem $border-color;
  }
  .partnership-flag {
    position: absolute;
    margin-right: 10px;
    top: 10px;
    right: 10px;
    background-color:blue;
    color: white;
    padding: 2px 10px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    z-index: 10;
  }
}

.image {
  object-fit: cover;
}

.packages-container {
  height: 200px;
  overflow-y: auto;
  position: relative;

  .ant-card {
    // border: 1px solid $border-color;
    border-radius: 4px;
    // margin-bottom: 6px;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px);
    }
  }
  // margin-bottom: 16px;
}

.area-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-to-overview {
  display: block;
  padding: 0;
  color: #1890ff;
  text-align: center;
  font-size: 1.3em;
  margin-top: 0; // Removing the space above the button
}

.back-to-overview-link {
  text-decoration: none;
  transition: text-decoration 0.3s;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 767px) {
  .packages-container {
    height: auto;
    max-height: 300px;
  }

  .area-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .area-container > * {
    margin-bottom: 8px;
  }

  .back-to-overview-link {
    font-size: 1.1em;
  }
}

@import "../../variables";
.card-container-mobile {
  cursor: pointer;
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 0.75rem;
  border: 1px solid $border-color;
  transition: all 150ms ease-out;
  overflow: hidden;
  img {
    border-radius: 0.5rem;
    object-fit: cover;
  }
  .rate-partnerRow {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  .partnership-flag {
    width: fit-content;
    background-color: blue;
    color: white;
    padding: 2px 10px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
  }
}


.packages-container {
  padding: 0 16px;

  .ant-card {
    border: 1px solid $border-color;
    border-radius: 4px;
    // margin-bottom: 6px;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px);
    }
  }
}

.area-container {
  display: flex;
  justify-content: space-between;
}

.back-to-overview {
  display: block;
  padding: 0;
  color: #1890ff;
  text-align: center;
  font-size: 1.3em;
  margin-top: 0; // Removing the space above the button
}

.back-to-overview-link {
  text-decoration: underline;
  transition: text-decoration 0.3s;
}
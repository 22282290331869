//Font-Family
$font-family: "Arial", sans-serif;

//Font-Size
$large-font: 22px;
$medium-font: 16px;
$small-font: 12px;
$extra-small-font: 8px;

//Font-weight
$heading-font-weight: 700;
$subheading-font-weight: 600;
$normal-text-font-weight: 500;

//Color
$primary-color: #000000;
$secondary-color: #ffffff;
$tertiary-color: #483285;
$quaternary-color: #6c757d;
$quinary-color: #007bff;

//PADDING
$p-1: 1px;
$p-2: 2px;
$p-3: 3px;
$p-4: 4px;
$p-5: 5px;

//MARGIN
$m-1: 1px;
$m-2: 2px;
$m-3: 3px;
$m-4: 4px;
$m-5: 5px;

//Border Radius
$border-small: 4px;
$border-medium: 8px;
$border-large: 12px;

//Letter spacing
$letter-spacing-normal: 0.02em;
$letter-spacing-wide: 0.05em;

//Line height
$line-height-normal: 1.5;
$line-height-compact: 1.2;

//Updated color variables
$border-color: rgba(0, 0, 0, 0.15);
$border-color-hover: rgba(0, 0, 0, 0.25);
$hover-cursor-bg: rgba(57, 16, 133, 0.45);
$light-bg: rgba(57, 16, 133, 0.75);

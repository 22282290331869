.back-button {
  position: fixed;
  top: 5rem;
  left: 1rem;
  z-index: 10;
}

.not-available-container {
  text-align: center;
  margin: 6rem auto;
}

.not-available-container h1 {
  font-size: 36px;
  margin-bottom: 2rem;
}

.not-available-container p {
  font-size: 18px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .not-available-container {
    margin: 0px auto;
    padding: 3rem;
  }
  .not-available-container h1 {
    font-size: 28px;
  }

  .not-available-container p {
    font-size: 16px;
  }
}

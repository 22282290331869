@import "../../_variables.scss";
.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 3rem;
  border-top: 1px solid $border-color;

  .section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    width: fit-content;

    .pages {
      display: flex;
      gap: 0.25rem;
      flex-wrap: wrap;
      align-items: center;
    }

    .section-child {
      display: flex;
      flex-direction: column;
    }
  }

  span.company-heading.ant-typography {
    font-weight: 600;
    font-size: 2rem;
  }

  span.description {
    max-width: 500px;
  }

  .footer-social-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    .social-item a {
      display: flex;
      font-size: 1.25rem;
    }
  }
}

.footer ul {
  list-style: none;
}

.footer a {
  text-decoration: none;
}

.container {
  margin-top: 10px;
  .list-container {
    margin-left: 8px;
    .ant-spin-nested-loading {
      .ant-spin-container {
        div {
          max-width: 100%;
        }
      }
    }
  }
  .no-data {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    margin-top: 2rem;
    text-align: center;
  }
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: #25D366; /* Background color for the button */
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.whatsapp-button:hover {
  background-color: #1ebc75; /* Slightly darker shade on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.whatsapp-button .button-content {
  display: flex;
  align-items: center;
}

.whatsapp-button .whatsapp-icon {
  font-size: 24px; /* Size of the WhatsApp icon */
  color: white;
  margin-right: 10px;
}

.whatsapp-button .whatsapp__text {
  color: white;
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  align-items: center;
}

.whatsapp-button .whatsapp-text__icon {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.whatsapp-button .whatsapp-text__icon svg {
  fill: #ffffff; /* Icon color */
  vertical-align: middle;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .whatsapp-button {
    bottom: 15px;
    right: 15px;
  }
}


.offer-banner-img{
  padding: 0px 10px;
  margin: 0px 0px 20px 0px;
}

.turf-count{
  padding: 20px;
  font-weight: 600;
  font-size: 16px;
}

.turf-count > span{
  color: purple;
  font-size: 19px;
}
.carousel{
  cursor: pointer;
}

@import "./variables";
@import url("https://api.fontshare.com/v2/css?f[]=plein@1&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "plein", sans-serif !important;
}
body {
  background-color: #fff;
  h1 {
    font-size: 28px;
    color: #333;
    margin: 0;
  }
  .text-align-center {
    text-align: center;
  }
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-1 {
  margin-top: 4px;
}
.mb-1 {
  margin-bottom: 4px;
}
.ant-rate {
  font-size: 10px;
}

.text-white {
  color: white;
}

.ant-modal{
  width: fit-content !important;
}
@import "../../../../variables";

.search {
  width:40% ;
  display: flex;
  flex-direction: row;
  column-gap: 0px;  
  margin: auto 10px auto auto;
}
  .ant-space-item {
    display: flex;
    align-items: center;
    width: 100%;
    .ant-space-compact {
      width: 100%;
      line-height: 0 !important;
    }
  }
    .input-search {
      float: center;
    }
    @media (max-width: 768px) {
      .input-search {
        margin: auto 0px auto auto;
        float: center;
      }
      .search {
        width: 100%;
        border-radius: 0px !important;

      }

      .input-search {
        position: absolute;
        top: 12px;
        left: 0px;
        padding: 0px 10px;
      }

  @media (max-width: 768px) {
    .input-search {
      margin: auto 0px auto auto;
      float: center;
      position: fixed;
      top: 12px;
      left: 0px;
      padding: 0px 10px;
      width: 100%;
    & {
      width: 100%;
    }
  }
}
}

.search-icon {
  height: 30px;
  width: 30px;
  color: white;
  padding: 5px;
  font-size: 20px;
}

.search-affix-icon {
  display: flex;
  align-items: center;
  justify-content: end;
}

.input-autocomplete{
  width: 60%;
}
